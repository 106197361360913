<template>
    <div class="main_bd">
        <div class="setting">
            <div class="title">
                <span>Guest Credit Detail</span>
                <div class="action">
                    <a-button type="primary" @click="$router.go(-1)"> Back </a-button>
                </div>
            </div>
            <div class="line"></div>
            <div class="row-box">
                <div class="column">
                    <div class="label">Phone Number:</div>
                    <div class="value">{{ customerData.phone }}</div>
                </div>

                <div class="column">
                    <div class="label">Name:</div>
                    <div class="value">{{ customerData.name }}</div>
                </div>
            </div>
            <div class="row-box">
                <div class="column">
                    <div class="label">Customer ID:</div>
                    <div class="value">{{ memberData.card_number }}</div>
                </div>
            </div>
            <div class="current">
                <div class="label">Current Credits: ${{ (Number(memberData.credit) || 0).toFixed(2) }}</div>
                <a-button size="large" @click="cashoutVisible = true">Cash Out</a-button>
            </div>
            <a-checkbox @change="onChangeStatus" style="margin-bottom: 20px" v-model="statusCheck"> Only show completed transactions </a-checkbox>
            <a-table :columns="columns" :pagination="paginationAll" :data-source="list" :scroll="{ x: true }" row-key="coid">
                <div slot="change" slot-scope="text">
                    <div style="color: #1aad19" v-if="text > 0">+{{ text }}</div>
                    <div style="color: #ff4d4f" v-if="text < 0">{{ text }}</div>
                </div>
                <div slot="oid" slot-scope="text, row">
                    <a class="active-id" v-if="row.foreign_id" :href="orderDetail(row)" target="_blank">{{ row.foreign_id.split(':')[0] }}</a>
                    <div class="active-id" v-else @click="creditDetail(row)">{{ row.coid }}</div>
                </div>
            </a-table>
        </div>
        <!-- modal -->
        <a-modal v-model="cashoutVisible" title="Enter Cash Out Amount" ok-text="Confirm" @ok="cashout">
            <a-input-number v-model="cashoutChange" :min="0.01" :precision="2" style="width: 300px"></a-input-number>
        </a-modal>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import axiosApi from '../../kits/axiosApi';
import moment from 'moment-timezone';

export default {
    name: 'CreditDetail',
    data() {
        return {
            sid: '',
            storeDict: {},
            moment,
            cid: '',
            customerData: {},
            memberData: {},
            list: [],
            statusCheck: false,
            cashoutVisible: false,
            cashoutChange: '',
            paginationAll: {
                current: 1,
                pageSize: 10,
                showTotal: (total) => `Total ${total} items`, // 显示总数
                onChange: (page, pageSize) => this.changePageAll(page), //点击页码事件
                total: 0,
                hideOnSinglePage: true
            },
            columns: [
                {
                    title: 'Time',
                    dataIndex: 'create_time',
                    key: 'create_time'
                },
                {
                    title: 'Location',
                    dataIndex: 'name',
                    key: 'name',
                    customRender: (text, row, index) => {
                        return this.storeDict[row.sid].nick_name;
                    }
                },
                {
                    title: 'Activity',
                    dataIndex: 'channel',
                    key: 'channel',
                    customRender: (text, row, index) => {
                        if (row.log_type == 'Cash-out') {
                            return 'Cash out';
                        }
                        return `${row.log_type}(${row.channel})`;
                    }
                },
                {
                    title: 'Change',
                    dataIndex: 'change',
                    key: 'change',
                    scopedSlots: { customRender: 'change' }
                },
                {
                    title: 'Balance',
                    dataIndex: 'balance',
                    key: 'balance',
                    customRender: (text, row, index) => {
                        if (row.status == 0 || row.status == 1) {
                            return '-';
                        } else {
                            return text;
                        }
                    }
                },
                {
                    title: 'Order Status',
                    dataIndex: 'status',
                    key: 'status',
                    customRender: (text, row, index) => {
                        if (row.status == 0) {
                            return 'Pending';
                        } else if (row.status == 1) {
                            return 'Processing';
                        } else if (row.status == 4) {
                            return 'Completed';
                        } else if (row.status == 11) {
                            return 'Canceled';
                        }
                    }
                },
                {
                    title: 'Pay Status',
                    dataIndex: 'paid',
                    key: 'paid',
                    customRender: (text, row, index) => {
                        if (row.change < 0) {
                            return '-';
                        }
                        if (row.paid == 0) {
                            return 'Unpaid';
                        } else if (row.paid == 1) {
                            return 'Paid';
                        } else if (row.paid == 2) {
                            return 'Refund';
                        }
                    }
                },
                {
                    title: 'Order ID',
                    dataIndex: 'coid',
                    key: 'coid',
                    scopedSlots: { customRender: 'oid' }
                }
            ]
        };
    },
    components: {},
    async mounted() {
        this.sid = this.$route.query.sid;
        this.storeDict = await this.queryStore();
        this.cid = this.$route.params.cid;
        this.queryCustomer();
        this.queryMember();
        this.queryLog();
    },
    computed: {
        ...mapGetters({
            aj_host: 'get_aj_host',
            chain_id: 'app/get_chain_id',
            timezone: 'app/get_timezone'
        })
    },
    watch: {},
    methods: {
        ...mapActions({
            queryStore: 'app/queryStore'
        }),
        ...mapMutations({
            setLoading: 'app/APP_SET_LOADING'
        }),
        creditDetail(row) {
            this.$router.push({ path: '/credits/detail/' + row.coid });
        },
        changePageAll(page) {
            this.paginationAll.current = page;
            this.queryLog();
        },
        queryCustomer() {
            this.setLoading({
                show_loading: true
            });
            axiosApi
                .get(this.aj_host + '/weapp/admin/loyalty/customers', { params: { sid: this.sid, id: this.cid } })
                .then((res) => {
                    this.customerData = res.data.data.data;

                    this.setLoading({
                        show_loading: false
                    });
                })
                .catch((err) => {
                    this.setLoading({
                        show_loading: false
                    });
                });
        },
        queryMember() {
            axiosApi.get(this.aj_host + '/weapp/admin/loyalty/members', { params: { sid: this.sid, cid: this.cid } }).then((res) => {
                this.memberData = res.data.data.data[0];
            });
        },
        queryLog() {
            this.setLoading({
                show_loading: true
            });
            let stimestamp = moment('2023-02-01').tz(this.timezone, true).valueOf();
            let etimestamp = moment().add(5, 'minutes').valueOf();
            let params = { chain_id: this.chain_id, keyword: this.cid, page: this.paginationAll.current, page_size: this.paginationAll.pageSize, stimestamp, etimestamp };
            if (this.statusCheck) {
                params.status = 4;
            }
            axiosApi
                .get(this.aj_host + '/weapp/admin/credit/log/list', {
                    params
                })
                .then((res) => {
                    this.paginationAll.total = res.data.data.data.total_count;
                    this.list = res.data.data.data.list;
                    this.setLoading({
                        show_loading: false
                    });
                })
                .catch((err) => {
                    this.setLoading({
                        show_loading: false
                    });
                });
        },
        cashout() {
            if (!this.cashoutChange) {
                this.$notification['warning']({
                    message: 'Please input cashout amount.'
                });
                return;
            }
            if (this.cashoutChange > this.memberData.credit) {
                this.$notification['warning']({
                    message: 'Insufficient balance.'
                });
                return;
            }
            this.$confirm({
                title: `Confirm to Cash Out $${this.cashoutChange} credits?`,
                okText: 'Cash Out',
                cancelText: 'Cancel',
                onOk: () => {
                    this.setLoading({
                        show_loading: true
                    });
                    axiosApi
                        .post(this.aj_host + '/weapp/admin/credit/cashout', { sid: this.sid, card_number: this.memberData.card_number, change: this.cashoutChange })
                        .then((res) => {
                            if (res.data.data.code == 100000) {
                                this.$notification['success']({
                                    message: 'Successfully!'
                                });
                            } else {
                                this.$notification['warning']({
                                    message: res.data.data.msg
                                });
                            }
                            this.cashoutVisible = false;
                            this.setLoading({
                                show_loading: false
                            });
                            this.queryLog();
                            this.queryMember();
                        })
                        .catch((err) => {
                            this.setLoading({
                                show_loading: false
                            });
                        });
                },
                onCancel() {}
            });
        },
        orderDetail(row) {
            let orderType;
            if (row.channel == 'DI' || row.channel == 'DI Tip') {
                orderType = 'scan';
            } else if (row.channel == 'TO') {
                orderType = 'takeout';
            } else if (row.channel == 'OO') {
                orderType = 'takeout';
            }
            let foreign_id = row.foreign_id.split(':')[0];
            return `/orderdetail?orderType=${orderType}&oid=${foreign_id}`;
        },
        onChangeStatus() {
            this.paginationAll.current = 1;
            this.queryLog();
        }
    }
};
</script>
<style scoped>
.setting {
    padding: 20px 30px 30px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px 2px rgb(150 150 150 / 30%);
}

.title {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 18px;
}
.line {
    margin: 20px 0;
    width: 100%;
    height: 1px;
    background-color: #c5c5c5;
}
.row-box {
    display: flex;
    margin-bottom: 10px;
}
.row-box .column {
    display: flex;
    margin-right: 40px;
}
.row-box .label {
    margin-right: 10px;
    font-weight: 600;
}
.signature {
    width: 160px;
}
.active-id {
    color: #1aad19;
    text-decoration: underline;
    cursor: pointer;
}
.current {
    display: flex;
    justify-content: space-between;
    margin: 30px 0 10px;
    font-weight: 600;
    font-size: 24px;
}
</style>
