<template>
    <div class="main">
        <div class="main-header">
            <h4>All Members</h4>
            <div style="margin-left: auto; margin-right: 20px">sort by:</div>
            <a-select v-model="sort" style="width: 180px" @change="onChangeSort">
                <a-select-option value="createdate DESC"> Recently Updated </a-select-option>
                <a-select-option value="credit DESC"> Most credits </a-select-option>
                <a-select-option value="point DESC"> Most points </a-select-option>
                <!-- <a-select-option value="visit"> Most recent visit </a-select-option> -->
            </a-select>
        </div>
        <div class="main-body">
            <a-table :customRow="customRow" style="margin-top: 20px" :columns="columns" :pagination="pagination" :data-source="list" :scroll="{ x: true }" row-key="card_number"> </a-table>
        </div>
        <!-- pop -->
        <a-drawer :title="expandData.name || '-'" placement="right" :visible="visibleDetail" @close="visibleDetail = false" :width="600">
            <div class="column">
                <div class="label">Name</div>
                <div class="value">{{ expandData.name || '-' }}</div>
            </div>
            <div class="column">
                <div class="label">Phone</div>
                <div class="value">{{ expandData.phone }}</div>
            </div>
            <div class="column">
                <div class="label">Email</div>
                <div class="value">{{ expandData.email || '-' }}</div>
            </div>
            <div class="column">
                <div class="label">Birthday</div>
                <div class="value">{{ expandData.detail && expandData.detail.birthday }}</div>
            </div>
            <a-divider />
            <div class="column">
                <div class="label">Points</div>
                <div class="value digital" style="font-size: 26px">
                    <span>{{ expandData.point }}</span>
                    <span class="view" @click="openLog">View Log</span>
                </div>
            </div>
            <div class="column">
                <div class="label">Credit</div>
                <div class="value digital" style="font-size: 26px">
                    <span>${{ expandData.credit }}</span>
                    <span class="view" @click="guestDetail">View Details</span>
                </div>
            </div>
            <div style="width: 100%; text-align: center">
                <a-button class="black" type="primary" size="large" :disabled="expandData.blacklist == 1" @click="addBlack"> Blacklist </a-button>
            </div>
        </a-drawer>
        <a-modal title="Log detail" v-model="visibleLog" :width="800" @cancel="cancelLog">
            <a-table :columns="columnsLog" :pagination="paginationLog" :data-source="listLog" :scroll="{ x: true }" row-key="id"> </a-table>
        </a-modal>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import axiosApi from '@/kits/axiosApi';
import moment from 'moment-timezone';

export default {
    name: 'test',
    components: {},
    data() {
        return {
            expandData: {},
            sort: 'update DESC',
            list: [],
            pagination: {
                current: 1,
                pageSize: 100,
                showTotal: (total) => `Total ${total} items`, // 显示总数
                onChange: (page, pageSize) => this.changePage(page), //点击页码事件
                total: 0,
                hideOnSinglePage: true
            },
            columns: [
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    customRender: (text, row, index) => {
                        return text || '-';
                    }
                },
                {
                    title: 'ID',
                    dataIndex: 'card_number',
                    key: 'card_number',
                    customRender: (text, row, index) => {
                        return text || '-';
                    }
                },
                {
                    title: 'Phone',
                    dataIndex: 'phone',
                    key: 'phone'
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                    key: 'email',
                    customRender: (text, row, index) => {
                        return text || '-';
                    }
                },
                {
                    title: 'Points',
                    dataIndex: 'point',
                    key: 'point'
                },
                {
                    title: 'Credits',
                    dataIndex: 'credit',
                    key: 'credit'
                }
            ],
            storeDict: {},
            listLog: [],
            paginationLog: {
                current: 1,
                pageSize: 10,
                showTotal: (total) => `Total ${total} items`, // 显示总数
                onChange: (page, pageSize) => this.changePageLog(page), //点击页码事件
                total: 0,
                hideOnSinglePage: true
            },
            columnsLog: [
                {
                    title: 'Location',
                    dataIndex: 'name',
                    key: 'name',
                    customRender: (text, row, index) => {
                        return this.storeDict[row.sid].nick_name;
                    }
                },
                {
                    title: 'Activity',
                    dataIndex: 'activity',
                    key: 'activity',
                    customRender: (text, row, index) => {
                        return row.payload.name;
                    }
                },
                {
                    title: 'Change',
                    dataIndex: 'point',
                    key: 'point'
                },
                {
                    title: 'Point',
                    dataIndex: 'phone',
                    key: 'phone',
                    customRender: (text, row, index) => {
                        return (row.point + row.before).toFixed(0);
                    }
                },
                {
                    title: 'Time',
                    dataIndex: 'createdate',
                    key: 'createdate',
                    customRender: (text, row, index) => {
                        return moment(row.createdate).tz(this.storeDict[row.sid].timezone).format('YYYY/MM/DD hh:mm a');
                    }
                }
            ],
            visibleDetail: false,
            visibleLog: false
        };
    },
    beforeMount() {},
    async mounted() {
        this.storeDict = await this.queryStore();
        this.queryList();
    },
    updated() {},
    beforeCreate() {},
    created() {},
    watch: {},
    computed: {
        ...mapGetters({
            aj_host: 'get_aj_host',
            chain_id: 'app/get_chain_id',
            timezone: 'app/get_timezone',
            user_name: 'app/get_user_name'
        }),
        ...mapState({})
    },
    methods: {
        ...mapActions({
            queryStore: 'app/queryStore'
        }),
        ...mapMutations({
            setLoading: 'app/APP_SET_LOADING'
        }),
        customRow(record, index) {
            return {
                on: {
                    click: (event) => {
                        this.expandData = record;
                        this.visibleDetail = true;
                    }
                }
            };
        },
        changePage(page) {
            this.pagination.current = page;
            this.queryList();
        },
        queryList() {
            this.setLoading({
                show_loading: true
            });
            let params = {
                chain_id: this.chain_id,
                page: this.pagination.current,
                size: this.pagination.pageSize,
                sort: this.sort
            };
            axiosApi
                .get(this.aj_host + '/weapp/admin/member/get', {
                    params
                })
                .then((res) => {
                    this.list = res.data.data.data.list;
                    this.pagination.total = res.data.data.data.total;
                    this.setLoading({
                        show_loading: false
                    });
                })
                .catch((err) => {
                    this.setLoading({
                        show_loading: false
                    });
                });
        },
        onChangeSort() {
            this.pagination.current = 1;
            this.queryList();
        },

        openLog() {
            this.queryLog();
            this.visibleLog = true;
        },
        cancelLog() {
            this.paginationLog.current = 1;
        },
        changePageLog(page) {
            this.paginationLog.current = page;
            this.queryLog();
        },
        queryLog() {
            this.setLoading({
                show_loading: true
            });
            let params = {
                chain_id: this.chain_id,
                card_number: this.expandData.card_number,
                page: this.paginationLog.current,
                size: this.paginationLog.pageSize
            };
            axiosApi
                .get(this.aj_host + '/weapp/admin/member/log', {
                    params
                })
                .then((res) => {
                    this.listLog = res.data.data.data.list;
                    this.paginationLog.total = res.data.data.data.total;
                    this.setLoading({
                        show_loading: false
                    });
                })
                .catch((err) => {
                    this.setLoading({
                        show_loading: false
                    });
                });
        },
        guestDetail() {
            this.$router.push({
                path: '/credits/guest_detail/' + this.expandData.cid,
                query: {
                    sid: this.expandData.sid
                }
            });
        },
        addBlack() {
            this.setLoading({
                show_loading: true
            });
            axiosApi
                .post(this.aj_host + '/weapp/admin/loyalty/blacklist/edit', {
                    sid: this.expandData.sid,
                    cid: this.expandData.cid,
                    block: 1
                })
                .then((res) => {
                    if (res.data.data.data.code == 100000) {
                        this.$notification['success']({
                            message: 'Successfully!'
                        });
                        this.expandData.blacklist = 1;
                    }
                    this.setLoading({
                        show_loading: false
                    });
                })
                .catch((err) => {});
        }
    }
};
</script>

<style lang="less" scoped>
.column {
    margin-bottom: 20px;
    .label {
        font-size: 12px;
    }
    .value {
        font-weight: 600;
        font-size: 20px;
        &.digital {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .view {
                color: @main-color;
                text-decoration: underline;
                font-size: 14px;
                cursor: pointer;
            }
        }
    }
}
.black {
    margin-top: 100px;
    width: 200px;
}
</style>
