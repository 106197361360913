<template>
    <div class="main">
        <div class="main-header">
            <h4>All Locations</h4>
        </div>
        <div class="main-body">
            <a-table style="margin-top: 20px" :columns="columns" :pagination="pagination" :data-source="list" :scroll="{ x: true }" row-key="id">
                <div slot="action" slot-scope="text, row">
                    <a-button type="link" @click="editName(row)"> edit nickname </a-button>
                </div>
            </a-table>
        </div>
        <!-- pop -->
        <a-modal title="Password" v-model="visible" @ok="handleOk">
            <div>Nickname</div>
            <a-input v-model="nickname" placeholder="Nickname" :max-length="30" />
        </a-modal>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import axiosApi from '@/kits/axiosApi';
import moment from 'moment-timezone';

export default {
    name: 'test',
    components: {},
    data() {
        return {
            visible: false,
            nickname: '',
            sid: '',

            list: [],
            pagination: {
                current: 1,
                pageSize: 10,
                showTotal: (total) => `Total ${total} items`, // 显示总数
                onChange: (page, pageSize) => this.changePage(page), //点击页码事件
                total: 0,
                hideOnSinglePage: true
            },
            columns: [
                {
                    title: 'Nickname',
                    dataIndex: 'nick_name',
                    key: 'nick_name'
                },
                {
                    title: 'Address',
                    dataIndex: 'address',
                    key: 'address',
                    customRender: (text, row, index) => {
                        return row.street_address + ', ' + row.region;
                    }
                },
                {
                    title: 'City,State',
                    dataIndex: 'city',
                    key: 'city',
                    customRender: (text, row, index) => {
                        return row.locality + ', ' + row.country;
                    }
                },
                {
                    title: 'Phone',
                    dataIndex: 'stel',
                    key: 'stel'
                },
                {
                    title: 'Email',
                    dataIndex: 'user_name',
                    key: 'user_name'
                },
                {
                    title: 'Action',
                    dataIndex: 'channel',
                    key: 'channel',
                    scopedSlots: { customRender: 'action' }
                }
            ]
        };
    },
    beforeMount() {},
    mounted() {
        this.queryList();
    },
    updated() {},
    beforeCreate() {},
    created() {},
    watch: {},
    computed: {
        ...mapGetters({
            aj_host: 'get_aj_host',
            chain_id: 'app/get_chain_id',
            timezone: 'app/get_timezone',
            user_name: 'app/get_user_name'
        }),
        ...mapState({})
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({
            setLoading: 'app/APP_SET_LOADING'
        }),
        changePage(page) {
            this.pagination.current = page;
            this.queryList();
        },
        queryList() {
            this.setLoading({
                show_loading: true
            });
            let params = {
                chain_id: this.chain_id,
                page: this.pagination.current,
                size: this.pagination.pageSize
            };
            axiosApi
                .get(this.aj_host + '/weapp/admin/store/get', {
                    params
                })
                .then((res) => {
                    this.list = res.data.data.data.list;
                    this.pagination.total = res.data.data.data.total;
                    this.setLoading({
                        show_loading: false
                    });
                })
                .catch((err) => {
                    this.setLoading({
                        show_loading: false
                    });
                });
        },
        editName(row) {
            this.nickname = row.nick_name;
            this.sid = row.sid;
            this.visible = true;
        },
        handleOk() {
            if (!this.nickname) {
                this.$notification['warning']({
                    message: 'Please input nickname!'
                });
                return;
            }
            if (this.nickname.length < 2 || this.nickname.length > 30) {
                this.$notification['warning']({
                    message: 'The nickname length is between 2-30.'
                });
                return;
            }
            this.setLoading({
                show_loading: true
            });
            axiosApi
                .post(this.aj_host + '/weapp/admin/store/nickname/update', {
                    chain_id: this.chain_id,
                    sid: this.sid,
                    nick_name: this.nickname
                })
                .then((res) => {
                    if (res.data.data.code == 100000) {
                        this.$notification['success']({
                            message: 'Successfully!'
                        });
                        this.visible = false;
                        this.queryList();
                    }
                    this.setLoading({
                        show_loading: false
                    });
                })
                .catch((err) => {
                    this.setLoading({
                        show_loading: false
                    });
                });
        }
    }
};
</script>

<style lang="less" scoped>
</style>
