import Vue from 'vue';
import Router from 'vue-router';
import store from './store';

import Login from './views/Login.vue';
import Account from './views/account/Account';
import Locations from './views/account/Locations';
import Member from './views/account/Member';
import CreditReports from './views/Credit/CreditReports';
import CreditTransactions from './views/Credit/CreditTransactions';
import CreditDetail from './views/Credit/CreditDetail';
import CreditDetailGuest from './views/Credit/CreditDetailGuest';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    linkExactActiveClass: 'is-active',
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        { path: '*', redirect: '/account' },

        { path: '/login', component: Login },
        { path: '/account', component: Account },
        { path: '/locations', component: Locations },
        { path: '/member', component: Member },
        { path: '/creditreports', component: CreditReports },
        { path: '/credits/transactions', component: CreditTransactions },
        { path: '/credits/detail/:coid', component: CreditDetail },
        { path: '/credits/guest_detail/:cid', component: CreditDetailGuest }
    ]
});

router.beforeEach(async (to, from, next) => {
    // store.commit('app/APP_SET_LOADING', { show_loading: false });
    next();
});

export default router;
