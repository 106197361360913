import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

import app from './modules/app';
import login from './modules/login';

Vue.use(Vuex);

let aj_host_admin;
if (window.location.hostname.indexOf('.net') > -1) {
    aj_host_admin = 'https://admin.minitable.net';
} else {
    aj_host_admin = 'https://admin.minitable.link';
}
const state = {
    debug: window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost',
    aj_host: window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost' ? 'http://127.0.0.1:7272' : '',
    aj_host_admin: aj_host_admin
};

const getters = {
    get_aj_host() {
        return state.aj_host;
    }
};

state.is_development = state.debug;

export default new Vuex.Store({
    modules: {
        app,
        login
    },
    state,
    getters,
    strict: state.debug,
    plugins: state.debug ? [createLogger()] : []
});
