<template>
    <div class="main">
        <div class="main-header">
            <h4>Credit Dashboard</h4>
        </div>
        <div class="main-body report-main">
            <div class="report-nav">
                <a-menu :selected-keys="['reports']" mode="horizontal">
                    <a-menu-item key="reports">
                        <router-link to="">Credit Reports</router-link>
                    </a-menu-item>
                    <a-menu-item key="transactions">
                        <router-link to="/credits/transactions">Credit Transactions</router-link>
                    </a-menu-item>
                </a-menu>
            </div>
            <div class="report-insight">
                <div class="report-title">
                    <h4>Data Insight</h4>
                    <span>updated on: {{update_time}}</span>
                </div>
                <div class="report_container">
                    <div class="sums_row">
                        <div class="sum_item">
                            <div class="green">Total Credit Load</div>
                            <div class="price">${{totalSummary.load_accumulated_amount}}</div>
                            <ul>
                                <li v-for="(item, index) in store_list" :key="index" >
                                    <span class="green">{{item.sname}}</span>
                                    <span>${{report_data[item.sid].summary.load_accumulated_amount}}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="sum_item">
                            <div class="green">Total Credit Use</div>
                            <div class="price">${{totalSummary.use_accumulated_amount}}</div>
                            <ul class="">
                                <li v-for="(item, index) in store_list" :key="index" >
                                    <span class="green">{{item.sname}}</span>
                                    <span>${{report_data[item.sid].summary.use_accumulated_amount}}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="sum_item">
                            <div class="green">Total Credit Out</div>
                            <div class="price">${{totalSummary.cashout_accumulated_amount}}</div>
                            <ul class="">
                                <li v-for="(item, index) in store_list" :key="index" >
                                    <span class="green">{{item.sname}}</span>
                                    <span>${{report_data[item.sid].summary.cashout_accumulated_amount}}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="sum_item">
                            <div class="green">Unused Credit</div>
                            <div class="price">${{totalSummary.current_credits}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="report-summary">
                <div class="report-title">
                    <h4>Summary Report</h4>
                </div>
                <div class="search-area">
                    <div class="locations">
                        <span>View Report at:</span>
                        <a-select v-model="locations" style="width: 150px; margin-left: 20px" @change="onChangeLocation">
                            <a-select-option value="all"> All locations </a-select-option>
                            <a-select-option v-for="(item, index) in store_list" :key="index" :value="item.sid"> {{ item.nick_name }} </a-select-option>
                        </a-select>
                    </div>
                    <div class="date">
                        <span>view</span>
                        <a-select v-model="dateAll" style="width: 150px; margin: 0 10px" @change="dateRangeAll = []">
                            <a-select-option :value="item.value" v-for="item in rangeList" :key="item.value">
                                {{ item.label }}
                            </a-select-option>
                        </a-select>
                        <a-range-picker v-model="dateRangeAll" @change="dateAll = 'custom'"></a-range-picker>
                        <a-button type="primary" style="margin: 0 10px 0 40px" @click="updateList">Update</a-button>
                    </div>
                    <div class="export">
                        <a-button type="primary" @click="export_all">
                            Export
                        </a-button>
                    </div>
                </div>
                <div class="report-table">
                    <dl class="">
                        <dt class="" style="color: #d1d1d1;">None</dt>
                        <dd class="title">Credit Activity</dd>
                        <dd class="">Credit Sale Load</dd>
                        <ul>
                            <li class="">POS</li>
                            <li class="">Minipass</li>
                            <li class="">Bonus disct</li>
                            <li class="">Net sale</li>
                        </ul>
                        <dd class="">Refunds to credits</dd>
                        <dd class="">USE</dd>
                        <ul>
                            <li class="">Dinein( POS )</li>
                            <li class="">Takeout (POS)</li>
                            <li class="">Loyalty (POS)</li>
                            <li class="">Online Ordering Webpage</li>
                        </ul>
                        <dd class="">Cash out</dd>
                        <!-- 这个数据暂时不支持 -->
                        <!-- <dd class="">Credit Order Refund</dd> -->
                        <dd class="">Unused Credit</dd>
                    </dl>
                    <dl class="report-item total-item">
                        <dt class="">Total</dt>
                        <dd class="title">
                            <span>Count</span>
                            <span>Amt</span>
                        </dd>
                        <dd class="">
                            <span>{{ currentTotalBreakdown.load_daily_count }}</span>
                            <span>${{ currentTotalBreakdown.load_daily_amount }}</span>
                        </dd>
                        <ul>
                            <li class="">
                                <span>{{ currentTotalBreakdown.load_pos_count }}</span>
                                <span>${{ currentTotalBreakdown.load_pos_amount }}</span>
                            </li>
                            <li class="">
                                <span>{{ currentTotalBreakdown.load_minipass_count }}</span>
                                <span>${{ currentTotalBreakdown.load_minipass_amount }}</span>
                            </li>
                            <li class="">
                                <span></span>
                                <span>${{ currentTotalBreakdown.load_daily_bonus_amount }}</span>
                            </li>
                            <li class="">
                                <span></span>
                                <span>${{ currentTotalBreakdown.load_net_sales_amount }}</span>
                            </li>
                        </ul>
                        <dd class="">
                            <span>{{ currentTotalBreakdown.load_refund_count }}</span>
                            <span>${{ currentTotalBreakdown.load_refund_amount }}</span>
                        </dd>
                        <dd class="">
                            <span>{{ currentTotalBreakdown.use_daily_count }}</span>
                            <span>-${{ currentTotalBreakdown.use_daily_amount }}</span>
                        </dd>
                        <ul>
                            <li class="">
                                <span>{{ currentTotalBreakdown.use_dinein_count }}</span>
                                <span>-${{ currentTotalBreakdown.use_dinein_amount }}</span>
                            </li>
                            <li class="">
                                <span>{{ currentTotalBreakdown.use_takeout_count }}</span>
                                <span>-${{ currentTotalBreakdown.use_takeout_amount }}</span>
                            </li>
                            <li class="">
                                <span>{{ currentTotalBreakdown.use_loyalty_count }}</span>
                                <span>-${{ currentTotalBreakdown.use_loyalty_amount }}</span>
                            </li>
                            <li class="">
                                <span>{{ currentTotalBreakdown.use_onlineordering_count }}</span>
                                <span>-${{ currentTotalBreakdown.use_onlineordering_amount }}</span>
                            </li>
                        </ul>
                        <dd class="">
                            <span>{{ currentTotalBreakdown.cashout_daily_count }}</span>
                            <span>-${{ currentTotalBreakdown.cashout_daily_amount }}</span>
                        </dd>
                        <!-- <dd class="">
                            <span></span>
                            <span>${{ currentTotalBreakdown.cashout_daily_amount }}</span>
                        </dd> -->
                        <dd class="">
                            <span></span>
                            <span>
                                <template v-if="currentTotalBreakdown.unused_credit_amount >= 0">
                                    ${{ currentTotalBreakdown.unused_credit_amount }}
                                </template>
                                <template v-else>
                                    -${{ currentTotalBreakdown.unused_credit_amount * -1 }}
                                </template>
                            </span>
                        </dd>
                    </dl>
                    <dl class="report-item" v-for="(item, index) in current_store_list" :key="index">
                        <dt class="">{{ item.nick_name }}</dt>
                        <dd class="title">
                            <span>Count</span>
                            <span>Amt</span>
                        </dd>
                        <dd class="">
                            <span>{{ report_data[item.sid].breakdown.load_daily_count }}</span>
                            <span>${{ report_data[item.sid].breakdown.load_daily_amount }}</span>
                        </dd>
                        <ul>
                            <li class="">
                                <span>{{ report_data[item.sid].breakdown.load_pos_count }}</span>
                                <span>${{ report_data[item.sid].breakdown.load_pos_amount }}</span>
                            </li>
                            <li class="">
                                <span>{{ report_data[item.sid].breakdown.load_minipass_count }}</span>
                                <span>${{ report_data[item.sid].breakdown.load_minipass_amount }}</span>
                            </li>
                            <li class="">
                                <span></span>
                                <span>${{ report_data[item.sid].breakdown.load_daily_bonus_amount }}</span>
                            </li>
                            <li class="">
                                <span></span>
                                <span>${{ report_data[item.sid].breakdown.load_net_sales_amount }}</span>
                            </li>
                        </ul>
                        <dd class="">
                            <span>{{ report_data[item.sid].breakdown.load_refund_count }}</span>
                            <span>${{ report_data[item.sid].breakdown.load_refund_amount }}</span>
                        </dd>
                        <dd class="">
                            <span>{{ report_data[item.sid].breakdown.use_daily_count }}</span>
                            <span>-${{ report_data[item.sid].breakdown.use_daily_amount }}</span>
                        </dd>
                        <ul>
                            <li class="">
                                <span>{{ report_data[item.sid].breakdown.use_dinein_count }}</span>
                                <span>-${{ report_data[item.sid].breakdown.use_dinein_amount }}</span>
                            </li>
                            <li class="">
                                <span>{{ report_data[item.sid].breakdown.use_takeout_count }}</span>
                                <span>-${{ report_data[item.sid].breakdown.use_takeout_amount }}</span>
                            </li>
                            <li class="">
                                <span>{{ report_data[item.sid].breakdown.use_loyalty_count }}</span>
                                <span>-${{ report_data[item.sid].breakdown.use_loyalty_amount }}</span>
                            </li>
                            <li class="">
                                <span>{{ report_data[item.sid].breakdown.use_onlineordering_count }}</span>
                                <span>-${{ report_data[item.sid].breakdown.use_onlineordering_amount }}</span>
                            </li>
                        </ul>
                        <dd class="">
                            <span>{{ report_data[item.sid].breakdown.cashout_daily_count }}</span>
                            <span>-${{ report_data[item.sid].breakdown.cashout_daily_amount }}</span>
                        </dd>
                        <!-- <dd class="">
                            <span></span>
                            <span>${{ report_data[item.sid].breakdown.cashout_daily_amount }}</span>
                        </dd> -->
                        <dd class="">
                            <span></span>
                            <span>
                                <template v-if="report_data[item.sid].breakdown.unused_credit_amount >= 0">
                                    ${{ report_data[item.sid].breakdown.unused_credit_amount }}
                                </template>
                                <template v-else>
                                    -${{ report_data[item.sid].breakdown.unused_credit_amount * -1 }}
                                </template>
                            </span>
                        </dd>
                    </dl>
                </div>
                <div class="report-title">
                    <h4>Inter -location Summary</h4>
                </div>
                <div class="">
                    <table class="location_table">
                        <thead>
                            <tr>
                                <th>Location</th>
                                <th>Load</th>
                                <th>Use</th>
                                <th>Cash Out</th>
                                <th>Net Amt</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="tr_sum" v-for="(item, index) in current_store_list" :key="index">
                                <td class="label">{{ item.nick_name }}</td>
                                <td>${{ report_data[item.sid].summary.load_accumulated_amount || 0 }}</td>
                                <td>${{ report_data[item.sid].summary.use_accumulated_amount || 0 }}</td>
                                <td>${{ report_data[item.sid].summary.cashout_accumulated_amount || 0 }}</td>
                                <td>${{ report_data[item.sid].summary.current_credits || 0 }}</td>
                            </tr>
                            <tr class="tr_sum">
                                <td class="label">Total</td>
                                <td>${{ currentTotalSummary.load_accumulated_amount || 0 }}</td>
                                <td>${{ currentTotalSummary.use_accumulated_amount || 0 }}</td>
                                <td>${{ currentTotalSummary.cashout_accumulated_amount || 0 }}</td>
                                <td>${{ currentTotalSummary.current_credits || 0 }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import axiosApi from '@/kits/axiosApi';
import moment from 'moment-timezone';

export default {
    name: 'CreditReports',
    components: {},
    data() {
        return {
            dateAll: 'this_week',
            dateRangeAll: [],
            sdate: '',
            edate: '',
            locations: 'all',
            sid_list: [],
            store_list: [],
            report_data: {},
            totalSummary: {},
            totalBreakdown: {},
            current_store_list: [],
            currentTotalSummary: {},
            currentTotalBreakdown: {},
            update_time: '',
            rangeList: [
                {
                    label: 'This week',
                    value: 'this_week',
                },
                {
                    label: 'Today',
                    value: 'today',
                },
                {
                    label: 'Yesterday',
                    value: 'yesterday',
                },
                {
                    label: 'This month',
                    value: 'this_month',
                },
                {
                    label: 'This year',
                    value: 'this_year',
                },
                {
                    label: 'Custom date',
                    value: 'custom',
                },
            ],
        };
    },
    beforeMount() {},
    mounted() {
        this.queryList();
    },
    updated() {},
    beforeCreate() {},
    created() {},
    watch: {},
    computed: {
        ...mapGetters({
            aj_host: 'get_aj_host',
            chain_id: 'app/get_chain_id',
            timezone: 'app/get_timezone',
            user_name: 'app/get_user_name'
        }),
        ...mapState({})
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({
            setLoading: 'app/APP_SET_LOADING'
        }),
        onChangeLocation(value) {
            this.locations = value;
        },
        updateList() {
            if (this.dateRangeAll.length != 2 && this.dateAll == 'custom') {
                this.$notification['warning']({
                    message: 'Please select a date range',
                })
                return
            }

            if (this.dateRangeAll.length == 2) {
                this.sdate = moment(this.dateRangeAll[0]).tz(this.timezone).format('YYYY-MM-DD')
                this.edate = moment(this.dateRangeAll[1]).tz(this.timezone).format('YYYY-MM-DD')
            } else {
                this.sdate = '';
                this.edate = '';
            }

            this.queryList();
        },
        changeStoreList() {
            if (this.locations != 'all') {
                let _temp_list = [];
                for (let i = 0; i < this.store_list.length; i++) {
                    let _temp_store = this.store_list[i];
                    if (this.locations == _temp_store.sid) {
                        _temp_list.push(_temp_store);
                    }
                }
                this.current_store_list = _temp_list;
                this.currentTotalSummary = this.report_data[this.locations].summary;
                this.currentTotalBreakdown = this.report_data[this.locations].breakdown;
            }
        },
        export_all() {
            this.setLoading({
                show_loading: true
            });
            let params = {
                chain_id: this.chain_id,
                duration: this.dateAll,
                sdate: this.sdate,
                edate: this.edate
            };
            axiosApi
                .get(this.aj_host + '/weapp/admin/credit/report', {
                    params
                })
                .then((res) => {
                    let report_data = res.data.data.data.report_data;
                    let breakdown_data = report_data.total.breakdown;
                    let store_list = res.data.data.data.store_list;

                    // 列标题，逗号隔开，每一个逗号就是隔开一个单元格
                    let cvsStr = '';
                    cvsStr += `,Total,,`;
                    for (let i = 0; i < store_list.length; i++) {
                        if (i == store_list.length - 1) {
                            cvsStr += store_list[i].nick_name + ',\n';
                        } else {
                            cvsStr += store_list[i].nick_name + ',,';
                        }
                    }
                    // cvsStr += `Activity,Count,Amt,`;
                    // for (let i = 0; i < store_list.length; i++) {
                    //     if (i == store_list.length - 1) {
                    //         cvsStr += 'Count,Amt/n';
                    //     } else {
                    //         cvsStr += 'Count,Amt,';
                    //     }
                    // }
                    cvsStr += this.forStoreList(report_data, store_list, 'Activity,Count,Amt,', false, 'Count,Amt');
                    cvsStr += this.forStoreList(report_data, store_list, `Credit sale load,${breakdown_data.load_daily_count},${breakdown_data.load_daily_amount},`, true, '', 'load_daily_count', 'load_daily_amount');
                    cvsStr += this.forStoreList(report_data, store_list, `POS,${breakdown_data.load_pos_count},${breakdown_data.load_pos_amount},`, true, '', 'load_pos_count', 'load_pos_amount');
                    cvsStr += this.forStoreList(report_data, store_list, `Minipas,${breakdown_data.load_minipass_count},${breakdown_data.load_minipass_amount},`, true, '', 'load_minipass_count', 'load_minipass_amount');
                    cvsStr += this.forStoreList(report_data, store_list, `Bonus Disct,,${breakdown_data.load_daily_bonus_amount},`, true, '', '', 'load_daily_bonus_amount');
                    cvsStr += this.forStoreList(report_data, store_list, `Net Sale,,${breakdown_data.load_net_sales_amount},`, true, '', '', 'load_net_sales_amount');
                    cvsStr += this.forStoreList(report_data, store_list, `Refunds to Credit,${breakdown_data.load_refund_count},${breakdown_data.load_refund_amount},`, true, '', 'load_refund_count', 'load_refund_amount');
                    cvsStr += this.forStoreList(report_data, store_list, `Use,${breakdown_data.use_daily_count},${breakdown_data.use_daily_amount},`, true, '', 'use_daily_count', 'use_daily_amount');
                    cvsStr += this.forStoreList(report_data, store_list, `Dinein POS,${breakdown_data.use_dinein_count},${breakdown_data.use_dinein_amount},`, true, '', 'use_dinein_count', 'use_dinein_amount');
                    cvsStr += this.forStoreList(report_data, store_list, `Takeouk POS,${breakdown_data.use_takeout_count},${breakdown_data.use_takeout_amount},`, true, '', 'use_takeout_count', 'use_takeout_amount');
                    cvsStr += this.forStoreList(report_data, store_list, `Loyalty POS,${breakdown_data.use_loyalty_count},${breakdown_data.use_loyalty_amount},`, true, '', 'use_loyalty_count', 'use_loyalty_amount');
                    cvsStr += this.forStoreList(report_data, store_list, `Online Ordering,${breakdown_data.use_onlineordering_count},${breakdown_data.use_onlineordering_amount},`, true, '', 'use_onlineordering_count', 'use_onlineordering_amount');
                    cvsStr += this.forStoreList(report_data, store_list, `Cash Out,${breakdown_data.cashout_daily_count},${breakdown_data.cashout_daily_amount},`, true, '', 'cashout_daily_count', 'cashout_daily_amount');
                    cvsStr += this.forStoreList(report_data, store_list, `Net Amount,,${breakdown_data.unused_credit_amount},`, true, '', '', 'unused_credit_amount');

                    let uploadExcel = (fileName, str) => {
                        // encodeURIComponent解决中文乱码
                        const uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(str);

                        const aLink = document.createElement("a");
                        aLink.setAttribute("download", fileName);
                        aLink.setAttribute("href", uri);
                        document.body.appendChild(aLink);
                        aLink.click();
                        document.body.removeChild(aLink);
                    };
                    uploadExcel("creditReports.csv", cvsStr);

                    this.setLoading({
                        show_loading: false
                    });
                })
                .catch((err) => {
                    this.setLoading({
                        show_loading: false
                    });
                });
        },
        forStoreList(report_data, store_list, titleStr, isKey, value, key1, key2) {
            let _str = '';
            _str += titleStr;
            for (let i = 0; i < store_list.length; i++) {
                if (i == store_list.length - 1) {
                    if (isKey) {
                        if (!key1) {
                            _str += ',' + report_data[store_list[i].sid].breakdown[key2] + '\n';
                        } else {
                            _str += report_data[store_list[i].sid].breakdown[key1] + ',' + report_data[store_list[i].sid].breakdown[key2] + '\n';
                        }
                        
                    } else {
                        _str += value +'\n';
                    }
                    
                } else {
                    if (isKey) {
                        if (!key1) {
                            _str += ',' + report_data[store_list[i].sid].breakdown[key2] + ',';
                        } else {
                            _str += report_data[store_list[i].sid].breakdown[key1] + ',' + report_data[store_list[i].sid].breakdown[key2] + ',';
                        }
                    } else {
                        _str += value + ',';
                    }
                }
            }

            return _str;
        },
        queryList() {
            this.setLoading({
                show_loading: true
            });
            let params = {
                chain_id: this.chain_id,
                duration: this.dateAll,
                sdate: this.sdate,
                edate: this.edate
            };
            axiosApi
                .get(this.aj_host + '/weapp/admin/credit/report', {
                    params
                })
                .then((res) => {
                    this.update_time = res.data.data.data.update_time;
                    this.store_list = res.data.data.data.store_list;
                    this.current_store_list = res.data.data.data.store_list;
                    this.report_data = res.data.data.data.report_data;
                    this.totalSummary = res.data.data.data.report_data.total.summary;
                    this.totalBreakdown = res.data.data.data.report_data.total.breakdown;
                    this.currentTotalSummary = res.data.data.data.report_data.total.summary;
                    this.currentTotalBreakdown = res.data.data.data.report_data.total.breakdown;
                    this.changeStoreList();
                    this.setLoading({
                        show_loading: false
                    });
                })
                .catch((err) => {
                    this.setLoading({
                        show_loading: false
                    });
                });
        },
    }
};
</script>

<style lang="less" scoped>
.report-main {
    .report-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 15px;
    }
    h4 {
        font-size: 18px;
    }
    .report_container{
        .report_section {
            display: flex;
        }
        .report_row {
            flex-grow: 1;
            width: 100px;
            text-align: center;
            font-size: 14px;
            &:first-child{
                margin-right: 23px;
            }
        }
        .sums_row{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 15px;
            width: 100%;
            .sum_item{
                flex-grow: 1;
                margin-right: 9px;
                padding: 5px 10px;
                // height: 82px;
                width: 10px;
                border: 2px solid #34C759;
                border-radius: 5px;
                background-color: rgba(255, 255, 255, 100);
                box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.4);
                color: rgba(16, 16, 16, 100);
                text-align: center;
                font-size: 20px;
                font-family: Roboto;
                .price{
                    font-weight: bold;
                    font-size: 22px;
                }
                &:last-child{
                    margin-right: 0;
                }
                .green {
                    color: #34C759;
                }
                ul {
                    margin: 0;
                    li {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 20px;
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .search-area {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .report-summary{
        .report-table {
            margin-top: 20px;
            display: flex;
            dl {
                flex: 1;
                color: #101010;
                &.report-item {
                    dt {
                        padding-left: 20px;
                    }
                    dd, ul li {
                        display: flex;
                        span {
                            display: block;
                            min-width: 50px;
                            padding: 0 10px;
                        }
                    }
                    ul {
                        padding-left: 0;
                    }
                }
                &.total-item ul li {
                    color: #101010;
                }
                dt {
                    font-size: 18px;
                    background-color: #d1d1d1;
                    padding-left: 10px;
                    line-height: 38px;
                }
                dd {
                    margin: 0;
                    background-color: #e1e1e1;
                    border-top: 1px solid #d1d1d1;
                    padding-left: 10px;
                    line-height: 36px;
                    &.title {
                        font-size: 16px;
                        background-color: #e5e5e5;
                        border-top: none;
                    }
                }
                ul {
                    margin: 0;
                    background-color: #fff;
                    padding-left: 10px;
                    li {
                        padding-left: 10px;
                        line-height: 32px;
                        border-top: 1px solid #e1e1e1e1;
                        color: #909090;
                    }
                }
            }
        }
        table.location_table{
            width: 100%;
            font-size: 15px;
            // border: 1px solid #d1d1d1;
            border: none;
            tr{
                border-bottom: 1px solid #ebebeb;
            }
            thead{
                tr{
                    border-bottom: 1px solid #e1e1e1;
                    th{
                        background-color: #e1e1e1;
                        color: #101010;
                        padding: 8px;
                        &:first-child{
                            padding-left: 20px;
                        }
                    }
                }
            }
            .tr_sum{
                border-bottom: 1px solid #e1e1e1;
                td{
                    background-color: #fff;
                    color: rgba(16, 16, 16, 100);
                }
            }
            td{
                padding: 8px;
                // box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4);
                &.label, &.label_sub{
                    text-align: left;
                    padding-left: 10px;
                }
                &.label_sub{
                    padding-left: 20px;
                }
                &:first-child{
                    padding-left: 20px;
                }
            }
        }
    }
}
</style>