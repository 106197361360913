<template>
    <div class="main">
        <div class="main-header">
            <h4>Sign In information</h4>
        </div>
        <div class="main-body">
            <div class="info">
                <div class="row">
                    <div class="column">
                        <div class="label">Master Account Email:</div>
                        <div class="value">{{ user_name }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <div class="label">Password:</div>
                        <div class="value">********</div>
                        <a-button type="link" style="margin-left: 40px" @click="visible = true"> edit </a-button>
                    </div>
                </div>
            </div>
            <template v-if="role == 'Admin' || role == 'Master'">
                <a-divider />
                <div class="title" style="display: flex; justify-content: space-between">
                    <span>Registered User List</span>
                    <!-- <a-button type="primary" :disabled="true"> Invite New User </a-button> -->
                </div>
                <a-table style="margin-top: 20px" :columns="columns" :pagination="pagination" :data-source="list" :scroll="{ x: true }" row-key="id">
                    <div slot="action" slot-scope="text">
                        <a-button type="link" :disabled="true"> resend invite </a-button>
                    </div>
                </a-table>
            </template>
        </div>
        <!-- pop -->
        <a-modal title="Password" v-model="visible" @ok="handleOk">
            <div>Current Password</div>
            <a-input-password v-model="oldPwd" placeholder="Current Password" :max-length="30" />
            <div style="margin-top: 20px">New Password</div>
            <a-input-password v-model="pwd1" placeholder="New Password" :max-length="30" />
            <div style="margin-top: 20px">Reenter New Password</div>
            <a-input-password v-model="pwd2" placeholder="Reenter New Password" :max-length="30" />
        </a-modal>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import axiosApi from '@/kits/axiosApi';
import md5 from 'md5';
import moment from 'moment-timezone';

export default {
    name: 'test',
    components: {},
    data() {
        return {
            visible: false,
            oldPwd: '',
            pwd1: '',
            pwd2: '',
            list: [],
            pagination: {
                current: 1,
                pageSize: 10,
                showTotal: (total) => `Total ${total} items`, // 显示总数
                onChange: (page, pageSize) => this.changePage(page), //点击页码事件
                total: 0,
                hideOnSinglePage: true
            },
            columns: [
                {
                    title: 'Email',
                    dataIndex: 'user_name',
                    key: 'user_name'
                },
                {
                    title: 'Invite Date',
                    dataIndex: 'create_time',
                    key: 'create_time',
                    customRender: (text, row, index) => {
                        return moment(Number(row.create_time)).tz(this.timezone).format('YYYY-MM-DD');
                    }
                },
                {
                    title: 'Role',
                    dataIndex: 'role',
                    key: 'role'
                }
                // {
                //     title: 'Action',
                //     dataIndex: 'action',
                //     key: 'action',
                //     scopedSlots: { customRender: 'action' }
                // }
            ]
        };
    },
    beforeMount() {},
    mounted() {
        this.queryList();
    },
    updated() {},
    beforeCreate() {},
    created() {},
    watch: {},
    computed: {
        ...mapGetters({
            aj_host: 'get_aj_host',
            chain_id: 'app/get_chain_id',
            role: 'app/get_role',
            timezone: 'app/get_timezone',
            user_name: 'app/get_user_name'
        }),
        ...mapState({})
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({
            setLoading: 'app/APP_SET_LOADING'
        }),
        changePage(page) {
            this.pagination.current = page;
            this.queryList();
        },
        queryList() {
            if (this.role != 'Admin' && this.role != 'Master') {
                return;
            }
            this.setLoading({
                show_loading: true
            });
            let params = {
                chain_id: this.chain_id,
                page: this.pagination.current,
                size: this.pagination.pageSize
            };
            axiosApi
                .get(this.aj_host + '/weapp/admin/user/get', {
                    params
                })
                .then((res) => {
                    this.list = res.data.data.data.list;
                    this.pagination.total = res.data.data.data.total;
                    this.setLoading({
                        show_loading: false
                    });
                })
                .catch((err) => {
                    this.setLoading({
                        show_loading: false
                    });
                });
        },
        handleOk() {
            if (!this.oldPwd) {
                this.$notification['warning']({
                    message: 'Please input old password!'
                });
                return;
            }
            if (!this.pwd1) {
                this.$notification['warning']({
                    message: 'Please input new password!'
                });
                return;
            }
            if (this.pwd1.length < 6 || this.pwd1.length > 30) {
                this.$notification['warning']({
                    message: 'The password length is between 6-30.'
                });
                return;
            }
            if (this.pwd1 !== this.pwd2) {
                this.$notification['warning']({
                    message: 'New password are not same!'
                });
                return;
            }
            this.setLoading({
                show_loading: true
            });
            axiosApi
                .post(this.aj_host + '/weapp/admin/user/password/update', {
                    chain_id: this.chain_id,
                    user_name: this.user_name,
                    old_password: md5(this.oldPwd),
                    new_password: md5(this.pwd1)
                })
                .then((res) => {
                    if (res.data.data.code == 100000) {
                        this.$notification['success']({
                            message: 'Successfully!'
                        });
                        this.visible = false;
                    }
                    this.setLoading({
                        show_loading: false
                    });
                })
                .catch((err) => {
                    this.setLoading({
                        show_loading: false
                    });
                });
        }
    }
};
</script>

<style lang="less" scoped>
.info {
    .row {
        display: flex;
        margin-bottom: 10px;
        .column {
            display: flex;
            align-items: center;
            margin-right: 40px;
            .label {
                margin-right: 10px;
                font-weight: 600;
            }
        }
    }
}
.title {
    font-weight: 600;
    font-size: 16px;
}
</style>
