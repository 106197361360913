<template>
    <div class="head blur_row">
        <div class="head_box js_head_box">
            <h1
                class="logo"
                style="padding-top: 15px; font-weight: 200; color: #777; font-family: 'Helvetica Neue', 'Hiragino Sans GB', 'Microsoft YaHei', '\9ED1\4F53', Arial, sans-serif; letter-spacing: normal"
            >
                Minitable
            </h1>

            <div class="header_ctrls">
                <div class="header_ctrls_meta top_user_box">
                    <a class="top_user_switch" href="javascript:;">
                        <img class="user_avatar" src="@/assets/images/minitable-logo.png" />
                    </a>
                    <i class="icon_arrow_right_global"></i>
                    <div class="skin_pop">
                        <div class="skin_pop_inner">
                            <div class="skin_pop_ft">
                                <a class="javascript:;" @click="do_logout" href="javascript:;">Log out</a>
                            </div>
                        </div>
                        <i class="icon_skin_pop_arrow"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import '@/assets/css/common.css';
import '@/assets/css/base.css';
export default {
    name: 'TopBar',
    data() {
        return {
            show_notifications: false,
            seen: false,
            iconData: 'down'
        };
    },
    mounted() {},
    methods: {
        clickNavigation: function () {
            var divV = document.getElementById('skin_pop');
            var divVDown = document.getElementById('top-down-icon');
            var divVUp = document.getElementById('top-up-icon');
            if (divV.style.display == 'none') {
                divV.style.display = 'inline';
                divVDown.style.display = 'none';
                divVUp.style.display = 'inline';
            } else {
                divV.style.display = 'none';
                divVDown.style.display = 'inline';
                divVUp.style.display = 'none';
            }
        },
        closeDisplay: function () {
            var divV = document.getElementById('skin_pop');
            var divVDown = document.getElementById('top-down-icon');
            var divVUp = document.getElementById('top-up-icon');
            divV.style.display = 'none';
            divVDown.style.display = 'inline';
            divVUp.style.display = 'none';
        },
        mouseOver: function () {
            var divV = document.getElementById('skin_pop');
            var divVDown = document.getElementById('top-down-icon');
            var divVUp = document.getElementById('top-up-icon');
            divV.style.display = 'inline';
            divVDown.style.display = 'none';
            divVUp.style.display = 'inline';
        },
        mouseLeave: function () {
            var divV = document.getElementById('skin_pop');
            var divVDown = document.getElementById('top-down-icon');
            var divVUp = document.getElementById('top-up-icon');
            divV.style.display = 'none';
            divVDown.style.display = 'inline';
            divVUp.style.display = 'none';
        },
        toggleNotificationBox: function () {
            this.show_notifications = !this.show_notifications;
        },
        read_all: function () {
            alert(1);
        },
        do_logout: function () {
            window.localStorage.removeItem('chain_id');
            window.localStorage.removeItem('token');
            window.localStorage.removeItem('role');
            window.localStorage.removeItem('user_name');

            window.location.replace('/login');
        }
    },
    computed: {}
};
</script>
<style lang="less" scoped>
</style>>