<template>
    <div class="main_bd">
        <div class="setting">
            <div class="title">
                <span>Credit {{ type == 1 ? 'Load' : 'Use' }} Order - {{ coid }}</span>
                <div class="action">
                    <!-- <a-button v-if="info.status == 11 && info.paid == 1" @click="refundVisible = true"> Full Refund </a-button> -->
                    <a-button type="danger" v-if="type == 1 && info.status == 0" @click="cancel"> Cancel </a-button>
                    <a-button type="danger" v-if="type == 1 && (info.status == 1 || info.status == 4)" @click="refundAndCancel"> Refund & Cancel </a-button>
                    <a-button type="primary" @click="$router.go(-1)" style="margin-left: 10px"> Back </a-button>
                </div>
            </div>
            <div class="line"></div>
            <div class="row-box">
                <div class="column">
                    <div class="label">Status:</div>
                    <div class="value" style="color: #1aad19" v-if="info.status == 0">Pending</div>
                    <div class="value" style="color: #1aad19" v-if="info.status == 1">Processing</div>
                    <div class="value" style="color: #1aad19" v-if="info.status == 4">Completed</div>
                    <div class="value" style="color: #ff4d4f" v-if="info.status == 11">Canceled</div>
                </div>
                <div class="column">
                    <div class="label">Credit Transaction Time:</div>
                    <div class="value">
                        {{ info.transaction_time ? moment(Number(info.transaction_time)).tz(timezone).format('YYYY/MM/DD hh:mma') : '-' }}
                    </div>
                </div>
            </div>
            <div class="row-box">
                <div class="column">
                    <div class="label">Order time:</div>
                    <div class="value">
                        {{ moment(Number(info.create_time)).tz(timezone).format('YYYY/MM/DD hh:mma') }}
                    </div>
                </div>
            </div>
            <div class="row-box">
                <div class="column">
                    <div class="label">Customer ID:</div>
                    <div class="value" style="cursor: pointer; color: #1aad19; text-decoration: underline" @click="guestDetail">{{ info.card_number }}</div>
                </div>
                <div class="column">
                    <div class="label">Phone Number:</div>
                    <div class="value">{{ info.phone_num }}</div>
                </div>
                <div class="column">
                    <div class="label">Name:</div>
                    <div class="value">{{ info.customer_name }}</div>
                </div>
            </div>
            <div class="line"></div>
            <div class="row-box" v-if="type == 1">
                <div class="column">
                    <div class="label">Credit Base Amt:</div>
                    <div class="value">${{ Number(info.paid_amount).toFixed(2) }}</div>
                </div>
                <div class="column">
                    <div class="label">Credit Bonus Amt:</div>
                    <div class="value">${{ Number(info.bonus_amount).toFixed(2) }}</div>
                </div>
                <div class="column">
                    <div class="label">Total Credit Added:</div>
                    <div class="value">${{ Number(info.paid_amount + info.bonus_amount).toFixed(2) }}</div>
                </div>
            </div>
            <a-table style="margin-top: 40px" :columns="type == 1 ? columnsIn : columnsOut" :data-source="list" :scroll="{ x: true }" row-key="change">
                <div slot="change" slot-scope="text">
                    <div style="color: #1aad19" v-if="text > 0">+{{ Number(text).toFixed(2) }}</div>
                    <div style="color: #ff4d4f" v-if="text < 0">{{ Number(text).toFixed(2) }}</div>
                </div>
                <div slot="channel" slot-scope="text, row">
                    <div v-if="row.log_type == 'Cash-out'">Cash out</div>
                    <div v-else-if="row.channel == 'Loyalty'">POS Loyalty</div>
                    <div v-else>{{ row.channel }}</div>
                </div>
            </a-table>
            <div style="margin: 40px 0 20px" v-if="info.order_info && info.order_info.sign_pic_url">Customer Signature</div>
            <img class="signature" :src="info.order_info.sign_pic_url" alt="" v-if="info.order_info && info.order_info.sign_pic_url" />
        </div>
        <!-- modal -->
        <a-modal v-model="refundVisible" title="">
            <span style="font-size: 16px; font-weight: 500">Confirm to refund?</span>
            <template slot="footer">
                <a-button key="card" @click="refundConfirm('yuansfer')" v-if="info.payment_type != 'cash'"> Refund to Card </a-button>
                <a-button key="cash" type="primary" @click="refundConfirm('cash')"> Cash Refund </a-button>
            </template>
        </a-modal>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import axiosApi from '../../kits/axiosApi';
import moment from 'moment-timezone';

export default {
    name: 'CreditDetail',
    data() {
        return {
            sid: '',
            moment,
            memberData: {},
            coid: '',
            info: {},
            list: [],
            type: 1, //1:充值等增加,2:使用支出等减少
            columnsIn: [
                {
                    title: 'Pay Time',
                    dataIndex: 'pay_time',
                    key: 'pay_time'
                },
                {
                    title: 'Payment method',
                    dataIndex: 'payment_type',
                    key: 'payment_type'
                },
                {
                    title: 'Card last 4',
                    dataIndex: 'card_data',
                    key: 'card_data'
                },
                {
                    title: 'Change',
                    dataIndex: 'change',
                    key: 'change',
                    scopedSlots: { customRender: 'change' }
                },
                {
                    title: 'Total',
                    dataIndex: 'amount',
                    key: 'amount',
                    customRender: (text, row, index) => {
                        return '$' + text;
                    }
                },
                {
                    title: 'Pay Status',
                    dataIndex: 'paid',
                    key: 'paid',
                    customRender: (text, row, index) => {
                        if (row.change < 0) {
                            return '-';
                        }
                        if (text == 0) {
                            return 'Unpaid';
                        } else if (text == 1) {
                            return 'Paid';
                        } else if (text == 2) {
                            return 'Refund';
                        }
                    }
                }
            ],
            columnsOut: [
                {
                    title: 'Channel',
                    dataIndex: 'channel',
                    key: 'channel',
                    scopedSlots: { customRender: 'channel' }
                },
                {
                    title: 'Change',
                    dataIndex: 'change',
                    key: 'change',
                    scopedSlots: { customRender: 'change' }
                }
            ],

            refundVisible: false
        };
    },
    components: {},
    mounted() {
        this.sid = this.$route.query.sid;
        this.coid = this.$route.params.coid;
        this.queryData();
    },
    computed: {
        ...mapGetters({
            aj_host: 'get_aj_host',
            chain_id: 'app/get_chain_id',
            timezone: 'app/get_timezone'
        })
    },
    watch: {},
    methods: {
        ...mapActions({}),
        ...mapMutations({
            setLoading: 'app/APP_SET_LOADING'
        }),
        guestDetail() {
            this.$router.push({
                path: '/credits/guest_detail/' + this.info.cid,
                query: {
                    sid: this.info.sid
                }
            });
        },
        queryData() {
            this.setLoading({
                show_loading: true
            });
            axiosApi
                .get(this.aj_host + '/weapp/admin/credit/log/info', { params: { sid: this.sid, coid: this.coid } })
                .then((res) => {
                    this.info = res.data.data.data.info;

                    if (this.info.change > 0) {
                        this.type = 1;
                    } else {
                        this.type = 2;
                    }
                    if (this.info.status == 4) {
                        this.queryMember();
                    }
                    let item = {
                        log_type: this.info.log_type,
                        change: this.info.change,
                        channel: this.info.channel,
                        pay_time: this.info.transaction_time ? moment(Number(this.info.transaction_time)).tz(this.timezone).format('YYYY/MM/DD hh:mma') : '-',
                        payment_type: this.info.payment_type ? this.info.payment_type : '-',
                        card_data: this.info.order_info && this.info.order_info.card_data.card_last4 ? this.info.order_info.card_data.card_last4 : '-',
                        amount: Number(this.info.paid_amount).toFixed(2),
                        paid: this.info.paid
                    };
                    this.list = [item];
                    this.setLoading({
                        show_loading: false
                    });
                })
                .catch((err) => {
                    this.setLoading({
                        show_loading: false
                    });
                });
        },
        refundAndCancel() {
            if (this.info.status == 4) {
                if (Number(this.memberData.credit) < this.info.paid_amount) {
                    this.$notification['warning']({
                        message: 'Cannot cancel order as credit balance is less than the load amount.'
                    });
                    return;
                }
            }
            this.refundVisible = true;
        },
        refundConfirm(method) {
            this.$confirm({
                title: 'Confirm to cancel?',
                okText: 'Cancel Order',
                cancelText: 'Back',
                onOk: async () => {
                    this.refundAj(method);
                },
                onCancel() {}
            });
        },
        refundAj(method) {
            return new Promise((resolve, reject) => {
                this.setLoading({
                    show_loading: true
                });
                axiosApi
                    .post(this.aj_host + '/weapp/admin/credit/refund', {
                        sid: this.sid,
                        coid: this.coid,
                        type: 'full',
                        method: method,
                        amount: this.info.paid_amount
                    })
                    .then(async (res) => {
                        if (res.data.data.code == 100000) {
                            this.$notification['success']({
                                message: 'Refund Successfully!'
                            });
                            await this.cancelAj();
                        } else {
                            this.$notification['warning']({
                                message: res.data.data.msg
                            });
                        }
                        this.setLoading({
                            show_loading: false
                        });
                        this.refundVisible = false;
                        this.queryData();
                        resolve();
                    })
                    .catch((err) => {
                        this.setLoading({
                            show_loading: false
                        });
                        reject();
                    });
            });
        },
        cancel() {
            this.$confirm({
                title: 'Confirm to cancel order?',
                onOk: async () => {
                    await this.cancelAj();
                    this.queryData();
                },
                onCancel() {}
            });
        },
        cancelAj() {
            return new Promise((resolve, reject) => {
                axiosApi
                    .post(this.aj_host + '/weapp/admin/credit/cancel', { sid: this.sid, coid: this.coid })
                    .then((res) => {
                        if (res.data.data.code == 100000) {
                            this.$notification['success']({
                                message: 'Cancel Successfully!'
                            });
                        } else {
                            this.$notification['warning']({
                                message: res.data.data.msg
                            });
                        }
                        resolve();
                    })
                    .catch((err) => {
                        reject();
                    });
            });
        },
        queryMember() {
            axiosApi.get(this.aj_host + '/weapp/admin/loyalty/members', { params: { sid: this.sid, card_number: this.info.card_number } }).then((res) => {
                this.memberData = res.data.data.data[0];
            });
        }
    }
};
</script>
<style scoped>
.setting {
    padding: 20px 30px 30px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px 2px rgb(150 150 150 / 30%);
}

.title {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 18px;
}
.line {
    margin: 20px 0;
    width: 100%;
    height: 1px;
    background-color: #c5c5c5;
}
.row-box {
    display: flex;
    margin-bottom: 10px;
}
.row-box .column {
    display: flex;
    margin-right: 40px;
}
.row-box .label {
    margin-right: 10px;
    font-weight: 600;
}
.signature {
    width: 160px;
}
</style>
