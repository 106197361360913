<template>
    <div class="main_bd">
        <div class="mod_default_hd">
            <h4>Credit Dashboard</h4>
        </div>
        <div class="setting">
            <div class="report-nav" style="margin-bottom: 40px">
                <a-menu :selected-keys="['transactions']" mode="horizontal">
                    <a-menu-item key="reports">
                        <router-link to="/creditreports">Credit Reports</router-link>
                    </a-menu-item>
                    <a-menu-item key="transactions">
                        <router-link to="/credits/transactions">Credit Transactions</router-link>
                    </a-menu-item>
                </a-menu>
            </div>
            <div class="search">
                <div style="margin-right: 10px">Search for transaction</div>
                <a-select v-model="dateSearch" style="width: 150px; margin: 0 10px" @change="dateRangeSearch = []">
                    <a-select-option :value="item.value" v-for="item in rangeList" :key="item.value">
                        {{ item.label }}
                    </a-select-option>
                </a-select>
                <a-range-picker v-model="dateRangeSearch" style="margin-right: 10px" @change="dateSearch = ''"></a-range-picker>
                <a-input-search v-model="keyword" style="width: 300px" placeholder="Customer ID/ COID/ OID/ Phone" enter-button @search="onSearch"></a-input-search>
            </div>
            <div style="font-weight: 500; margin: 40px 0 20px" v-if="searchTableShow">Search Results:</div>
            <a-table :columns="columnsSearch" :data-source="searchList" :pagination="paginationSearch" :scroll="{ x: true }" row-key="coid" v-if="searchTableShow">
                <div slot="date" slot-scope="text">
                    {{ moment(Number(text)).tz(timezone).format('YYYY/MM/DD hh:mma') }}
                </div>
                <div slot="change" slot-scope="text">
                    <div style="color: #1aad19" v-if="text > 0">+{{ Number(text).toFixed(2) }}</div>
                    <div style="color: #ff4d4f" v-if="text < 0">{{ Number(text).toFixed(2) }}</div>
                </div>
                <div slot="cid" slot-scope="text, row">
                    <div class="active-id" @click="guestDetail(row)">{{ text }}</div>
                </div>
                <div slot="oid" slot-scope="text, row">
                    <div v-if="row.foreign_id">{{ row.foreign_id.split(':')[0] }}</div>
                    <div class="active-id" v-else @click="creditDetail(row)">{{ row.coid }}</div>
                </div>
                <div slot="location" slot-scope="text, row">
                    {{ row.sid }}
                    <span>{{ storeDict[row.sid].nick_name }}</span>
                </div>
            </a-table>
            <div class="title">Transaction List</div>
            <div class="search-area">
                <div class="date">
                    <span>view</span>
                    <a-select v-model="dateAll" style="width: 150px; margin: 0 10px" @change="dateRangeAll = []">
                        <a-select-option :value="item.value" v-for="item in rangeList" :key="item.value">
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                    <a-range-picker v-model="dateRangeAll" @change="dateAll = ''"></a-range-picker>
                    <a-button type="primary" style="margin: 0 10px 0 40px" @click="updateList">Update</a-button>
                    <a-button>Export</a-button>
                </div>
                <div class="sort">
                    <span>View transactions at:</span>
                    <a-select v-model="locations" style="width: 160px; margin-left: 20px" @change="onChangeLocations">
                        <a-select-option value="all"> all locations </a-select-option>
                        <a-select-option :value="store.sid" v-for="store in storeDict" :key="store.sid"> {{ store.nick_name }} </a-select-option>
                    </a-select>
                </div>
            </div>
            <div style="display: flex; justify-content: space-between; align-items: center">
                <a-radio-group v-model="typeAll" button-style="solid" style="margin: 20px 0" @change="onChangeType">
                    <a-radio-button value="all"> All </a-radio-button>
                    <a-radio-button value="load"> Load </a-radio-button>
                    <a-radio-button value="use"> Use </a-radio-button>
                    <a-radio-button value="canceled"> Canceled </a-radio-button>
                    <a-radio-button value="refunded"> Refunded </a-radio-button>
                    <a-radio-button value="cashout"> Cash-out </a-radio-button>
                </a-radio-group>
                <div class="sort">
                    <span>sort by:</span>
                    <a-select v-model="sort" style="width: 100px; margin-left: 20px" @change="onChangeSort">
                        <a-select-option value="create_time desc"> Lastest </a-select-option>
                        <a-select-option value="create_time asc"> Oldest </a-select-option>
                    </a-select>
                </div>
            </div>

            <div class="filter" v-if="typeAll == 'load'">
                <div style="margin-right: 20px">advanced filter:</div>
                <a-button :type="paidType == 'all' ? 'primary' : ''" size="small" @click="onChangePaid('all')"> All </a-button>
                <div class="separate"></div>
                <a-button :type="paidType == '0' ? 'primary' : ''" size="small" style="margin-right: 20px" @click="onChangePaid('0')"> Unpaid </a-button>
                <a-button :type="paidType == '1' ? 'primary' : ''" size="small" style="margin-right: 20px" @click="onChangePaid('1')"> Paid </a-button>
                <a-button :type="paidType == '2' ? 'primary' : ''" size="small" style="margin-right: 20px" @click="onChangePaid('2')"> Refunded </a-button>
            </div>
            <a-table :columns="columnsAll" :data-source="list" :pagination="paginationAll" :scroll="{ x: true }" row-key="coid">
                <div slot="date" slot-scope="text">
                    {{ moment(text).tz(timezone, true).format('YYYY/MM/DD hh:mma') }}
                </div>
                <div slot="change" slot-scope="text">
                    <div style="color: #1aad19" v-if="text > 0">+{{ Number(text).toFixed(2) }}</div>
                    <div style="color: #ff4d4f" v-if="text < 0">{{ Number(text).toFixed(2) }}</div>
                </div>
                <div slot="cid" slot-scope="text, row">
                    <div class="active-id" @click="guestDetail(row)">{{ text }}</div>
                </div>
                <div slot="oid" slot-scope="text, row">
                    <div v-if="row.foreign_id">{{ row.foreign_id.split(':')[0] }}</div>
                    <div class="active-id" v-else @click="creditDetail(row)">{{ row.coid }}</div>
                </div>
                <div slot="amount" slot-scope="text, row">
                    <div style="color: #1aad19">${{ row.paid_amount }}</div>
                    <div style="color: #989898">({{ row.payment_type }})</div>
                </div>
                <div slot="location" slot-scope="text, row">
                    <span>{{ storeDict[row.sid].nick_name }}</span>
                </div>
            </a-table>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import axiosApi from '../../kits/axiosApi';
import moment from 'moment-timezone';
import { columnsAll, columnsLoad, columnsUse, columnsCanceled, columnsCashout, columnsRefunded } from './columns/columns';

export default {
    name: 'CreditSetting',
    data() {
        return {
            locations: 'all',
            storeDict: {},
            moment,
            tabActive: '2',
            dateAll: '',
            dateRangeAll: [],
            allStime: '',
            allEtime: '',
            typeAll: 'all',
            sort: 'create_time desc',
            list: [],

            searchTableShow: false,
            dateSearch: 'thisweek',
            dateRangeSearch: [],
            searchStime: '',
            searchEtime: '',
            searchList: [],
            keyword: '',
            paidType: 'all',
            rangeList: [
                {
                    label: 'This week',
                    value: 'thisweek'
                },
                {
                    label: 'This month',
                    value: 'thismonth'
                },
                {
                    label: 'Last 3 months',
                    value: 'last3months'
                },
                {
                    label: 'Last 6 months',
                    value: 'last6months'
                },
                {
                    label: 'This year',
                    value: 'thisyear'
                }
            ],
            paginationSearch: {
                current: 1,
                pageSize: 10,
                showTotal: (total) => `Total ${total} items`, // 显示总数
                onChange: (page, pageSize) => this.changePageSearch(page), //点击页码事件
                total: 0,
                hideOnSinglePage: true
            },
            paginationAll: {
                current: 1,
                pageSize: 50,
                showTotal: (total) => `Total ${total} items`, // 显示总数
                onChange: (page, pageSize) => this.changePageAll(page), //点击页码事件
                total: 0,
                hideOnSinglePage: true
            },
            columnsSearch: columnsAll,
            columnsAll: columnsAll
        };
    },
    components: {},
    async mounted() {
        this.storeDict = await this.queryStore();
        let page = this.$route.query.page;
        if (page) {
            this.paginationAll.current = Number(page);
            this.typeAll = this.$route.query.type;
            this.paidType = this.$route.query.paid;
            this.sort = this.$route.query.sort;

            let date_all = this.$route.query.date_all;
            let stime = this.$route.query.stime;
            let etime = this.$route.query.etime;
            if (date_all) {
                this.dateAll = date_all;
                this.dateRangeAll = [];
                let date = this.getDateRange(date_all);
                this.allStime = date.sdate;
                this.allEtime = date.edate;
            } else {
                this.dateRangeAll = [moment(Number(stime)).tz(this.timezone).format(), moment(Number(etime)).tz(this.timezone).format()];
                this.allStime = stime;
                this.allEtime = etime;
            }
        } else {
            let date = this.getDateRange('thisweek');
            this.dateAll = 'thisweek';
            this.allStime = date.sdate;
            this.allEtime = date.edate;
        }

        this.queryDataAll();
    },
    computed: {
        ...mapGetters({
            aj_host: 'get_aj_host',
            chain_id: 'app/get_chain_id',
            timezone: 'app/get_timezone',
        })
    },
    watch: {},
    methods: {
        ...mapActions({
            queryStore: 'app/queryStore'
        }),
        ...mapMutations({
            setLoading: 'app/APP_SET_LOADING'
        }),
        updateList() {
            if (this.dateRangeAll.length != 2 && !this.dateAll) {
                this.$notification['warning']({
                    message: 'Please select a date range'
                });
                return;
            }
            this.paginationAll.current = 1;
            if (this.dateRangeAll.length == 2) {
                this.allStime = moment(this.dateRangeAll[0]).tz(this.timezone).valueOf();
                this.allEtime = moment(this.dateRangeAll[1]).tz(this.timezone).valueOf();
            } else {
                let date = this.getDateRange(this.dateAll);
                this.allStime = date.sdate;
                this.allEtime = date.edate;
            }
            this.queryDataAll();
        },
        onChangeSort(e) {
            this.queryDataAll();
        },
        changePageAll(page) {
            this.paginationAll.current = page;
            this.queryDataAll();
        },
        onChangeType(e) {
            this.paidType = 'all';
            this.paginationAll.current = 1;
            this.queryDataAll();
        },
        onChangePaid(e) {
            this.paidType = e;
            this.paginationAll.current = 1;
            this.queryDataAll();
        },
        queryDataAll() {
            this.setLoading({
                show_loading: true
            });

            let params = {
                chain_id: this.chain_id,
                page: this.paginationAll.current,
                page_size: this.paginationAll.pageSize,
                stimestamp: this.allStime,
                etimestamp: this.allEtime,
                order_by: this.sort
            };
            if (this.typeAll == 'all') {
                this.columnsAll = columnsAll;
            } else if (this.typeAll == 'load') {
                this.columnsAll = columnsLoad;
                params.log_type = 'Load';
            } else if (this.typeAll == 'use') {
                this.columnsAll = columnsUse;
                params.log_type = 'Use';
            } else if (this.typeAll == 'canceled') {
                this.columnsAll = columnsCanceled;
                params.status = 11;
            } else if (this.typeAll == 'refunded') {
                this.columnsAll = columnsRefunded;
                params.paid = 2;
            } else if (this.typeAll == 'cashout') {
                this.columnsAll = columnsCashout;
                params.log_type = 'Cash-out';
            }

            if (this.paidType != 'all') {
                params.paid = this.paidType;
            }
            if (this.locations != 'all') {
                params.sid = this.locations;
            }

            this.$router.push(
                `${this.$route.path}?page=${this.paginationAll.current}&type=${this.typeAll}&paid=${this.paidType}&date_all=${this.dateAll}&stime=${this.allStime}&etime=${this.allEtime}&sort=${this.sort}`
            );

            axiosApi
                .get(this.aj_host + '/weapp/admin/credit/log/list', {
                    params
                })
                .then((res) => {
                    this.paginationAll.total = res.data.data.data.total_count;
                    this.list = res.data.data.data.list;
                    this.setLoading({
                        show_loading: false
                    });
                })
                .catch((err) => {
                    this.setLoading({
                        show_loading: false
                    });
                });
        },

        onSearch(keyword) {
            if (!keyword) {
                this.$notification['warning']({
                    message: 'Search content cannot be empty'
                });
                return;
            }
            if (this.dateRangeSearch.length != 2 && !this.dateSearch) {
                this.$notification['warning']({
                    message: 'Please select a date range'
                });
                return;
            }
            this.searchTableShow = true;
            this.paginationSearch.current = 1;
            if (this.dateRangeSearch.length == 2) {
                this.searchStime = moment(this.dateRangeSearch[0]).tz(this.timezone).valueOf();
                this.searchEtime = moment(this.dateRangeSearch[1]).tz(this.timezone).valueOf();
            } else {
                let date = this.getDateRange(this.dateSearch);
                this.searchStime = date.sdate;
                this.searchEtime = date.edate;
            }

            this.queryDataSearch();
        },
        changePageSearch(page) {
            this.paginationSearch.current = page;
            this.queryDataSearch();
        },
        queryDataSearch() {
            this.setLoading({
                show_loading: true
            });
            axiosApi
                .get(this.aj_host + '/weapp/admin/credit/log/list', {
                    params: {
                        chain_id: this.chain_id,
                        page: this.paginationSearch.current,
                        page_size: this.paginationSearch.pageSize,
                        stimestamp: this.searchStime,
                        etimestamp: this.searchEtime,
                        keyword: this.keyword
                    }
                })
                .then((res) => {
                    this.paginationSearch.total = res.data.data.data.total_count;
                    this.searchList = res.data.data.data.list;
                    this.setLoading({
                        show_loading: false
                    });
                })
                .catch((err) => {
                    this.setLoading({
                        show_loading: false
                    });
                });
        },
        getDateRange(range_type) {
            let res = {
                sdate: '',
                edate: ''
            };

            if (range_type == 'thisweek') {
                res['sdate'] = moment.tz(this.timezone).startOf('week').valueOf() + 5 * 60 * 60 * 1000;
                res['edate'] = moment.tz(this.timezone).valueOf();
            } else if (range_type == 'thismonth') {
                res['sdate'] = moment.tz(this.timezone).startOf('month').valueOf() + 5 * 3600 * 1000;
                res['edate'] = moment.tz(this.timezone).valueOf();
            } else if (range_type == 'last3months') {
                res['sdate'] = moment.tz(this.timezone).startOf('day').valueOf() - 90 * 24 * 60 * 60 * 1000 + 5 * 60 * 60 * 1000;
                res['edate'] = moment.tz(this.timezone).valueOf();
            } else if (range_type == 'last6months') {
                res['sdate'] = moment.tz(this.timezone).startOf('day').valueOf() - 180 * 24 * 60 * 60 * 1000 + 5 * 60 * 60 * 1000;
                res['edate'] = moment.tz(this.timezone).valueOf();
            } else if (range_type == 'thisyear') {
                res['sdate'] = moment.tz(this.timezone).startOf('year').valueOf() + 5 * 60 * 60 * 1000;
                res['edate'] = moment.tz(this.timezone).valueOf() + 5 * 60 * 60 * 1000 - 1 * 1000;
            }
            return res;
        },
        guestDetail(row) {
            this.$router.push({
                path: '/credits/guest_detail/' + row.cid,
                query: {
                    sid: row.sid
                }
            });
        },
        creditDetail(row) {
            this.$router.push({
                path: '/credits/detail/' + row.coid,
                query: {
                    sid: row.sid
                }
            });
        },
        orderDetail(row) {
            let orderType;
            if (row.channel == 'DI' || row.channel == 'DI Tip') {
                orderType = 'scan';
            } else if (row.channel == 'TO') {
                orderType = 'takeout';
            } else if (row.channel == 'OO') {
                orderType = 'takeout';
            }
            let foreign_id = row.foreign_id.split(':')[0];
            return `/orderdetail?orderType=${orderType}&oid=${foreign_id}`;
        },
        onChangeLocations() {
            this.paginationAll.current = 1;
            this.queryDataAll();
        }
    }
};
</script>
<style scoped>
.setting {
    padding: 20px 30px 30px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px 2px rgb(150 150 150 / 30%);
}
.search {
    display: flex;
    align-items: center;
}
.active-id {
    color: #1aad19;
    text-decoration: underline;
    cursor: pointer;
}
.title {
    margin: 40px 0;
    font-weight: 600;
    font-size: 18px;
}
.search-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.search-area .date {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #f8f9fb;
}

.filter {
    display: flex;
    margin-bottom: 20px;
}
.filter .separate {
    margin: 0 10px;
    width: 1px;
    height: 24px;
    background-color: #bcbcbc;
}
</style>
