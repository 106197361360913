<template>
    <div id="app">
        <router-view v-if="is_login"></router-view>

        <div v-if="!is_login">
            <TopBar></TopBar>
            <div class="body blur_row side_l" style="position: relative; background: #f8f9fb">
                <div class="col_side">
                    <LeftNav />
                </div>
                <div class="col_main">
                    <div class="col_main_inner">
                        <router-view v-if="isRouterAlive"></router-view>
                    </div>
                </div>
                <div class="loading-layer" v-show="show_loading">
                    <a-spin size="large"></a-spin>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import TopBar from '@components/TopBar';
import LeftNav from '@components/LeftNav';

export default {
    name: 'app',
    components: {
        TopBar,
        LeftNav
    },
    data() {
        return {
            is_login: this.$router.history.current.path.indexOf('/login') > -1 || this.$router.history.current.path.indexOf('/preorders/preordersqr') > -1 ? true : false,
            isRouterAlive: true
        };
    },
    provide() {
        return {
            reload: this.reload
        };
    },
    beforeMount() {},
    updated() {},
    beforeCreate() {},
    created() {},
    mounted() {},
    watch: {
        $route() {
            this.is_login = this.$router.history.current.path.indexOf('/login') > -1 || this.$router.history.current.path.indexOf('/preorders/preordersqr') > -1 ? true : false;
        }
    },
    computed: {
        ...mapGetters({
            show_loading: 'app/get_loading'
        }),
        ...mapState({})
    },
    methods: {
        ...mapActions({}),
        ...mapMutations({}),
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true;
            });
        }
    }
};
</script>

<style>
.loading-layer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #eee;
    opacity: 0.7;
}
.body {
    box-sizing: border-box;
    padding-bottom: 40px;
}

.body {
    padding-top: 40px;
}

.col_side {
    float: left;
    min-height: 1px;
    width: 230px;
}

.col_main {
    overflow: hidden;
    min-height: 700px;
}

.col_main .col_main_inner {
    margin: 0 auto;
}
</style>
