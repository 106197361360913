export const columnsAll = [
    {
        title: 'Credit order time',
        dataIndex: 'create_time',
        key: 'create_time'
    },
    {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        scopedSlots: { customRender: 'location' }
    },
    {
        title: 'Transaction Time',
        dataIndex: 'transaction_time',
        key: 'transaction_time'
    },
    {
        title: 'Customer ID',
        dataIndex: 'card_number',
        key: 'card_number',
        scopedSlots: { customRender: 'cid' }
    },
    {
        title: 'Phone',
        dataIndex: 'phone_num',
        key: 'phone_num'
    },
    {
        title: 'Activity',
        dataIndex: 'channel',
        key: 'channel',
        customRender: (text, row, index) => {
            if (row.log_type == 'Cash-out') {
                return 'Cash out';
            }
            return `${row.log_type}(${row.channel})`;
        }
    },
    {
        title: 'Change',
        dataIndex: 'change',
        key: 'change',
        scopedSlots: { customRender: 'change' }
    },
    {
        title: 'Server',
        dataIndex: 'server',
        key: 'server',
        customRender: (text, row, index) => {
            return text ? decodeURIComponent(text) : '-';
        }
    },
    {
        title: 'Order Status',
        dataIndex: 'status',
        key: 'status',
        customRender: (text, row, index) => {
            if (row.status == 0) {
                return 'Pending';
            } else if (row.status == 1) {
                return 'Processing';
            } else if (row.status == 4) {
                return 'Completed';
            } else if (row.status == 11) {
                return 'Canceled';
            }
        }
    },
    {
        title: 'Order ID',
        dataIndex: 'coid',
        key: 'coid',
        scopedSlots: { customRender: 'oid' }
    }
];

export const columnsLoad = [
    {
        title: 'Credit Order Time',
        dataIndex: 'create_time',
        key: 'create_time'
    },
    {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        scopedSlots: { customRender: 'location' }
    },
    {
        title: 'Transaction Time',
        dataIndex: 'transaction_time',
        key: 'transaction_time'
    },

    {
        title: 'Customer ID',
        dataIndex: 'card_number',
        key: 'card_number',
        scopedSlots: { customRender: 'cid' }
    },
    {
        title: 'Phone',
        dataIndex: 'phone_num',
        key: 'phone_num'
    },
    {
        title: 'Channel',
        dataIndex: 'channel',
        key: 'channel'
    },
    {
        title: 'Change',
        dataIndex: 'change',
        key: 'change',
        scopedSlots: { customRender: 'change' }
    },
    {
        title: 'Amt Paid',
        dataIndex: 'paid_amount',
        key: 'paid_amount',
        scopedSlots: { customRender: 'amount' }
    },
    {
        title: 'Order Status',
        dataIndex: 'status',
        key: 'status',
        customRender: (text, row, index) => {
            if (row.status == 0) {
                return 'Pending';
            } else if (row.status == 1) {
                return 'Processing';
            } else if (row.status == 4) {
                return 'Completed';
            } else if (row.status == 11) {
                return 'Canceled';
            }
        }
    },
    {
        title: 'Pay Status',
        dataIndex: 'paid',
        key: 'paid',
        customRender: (text, row, index) => {
            if (row.change < 0) {
                return '-';
            }
            if (row.paid == 0) {
                return 'Unpaid';
            } else if (row.paid == 1) {
                return 'Paid';
            } else if (row.paid == 2) {
                return 'Refund';
            }
        }
    },
    {
        title: 'Server',
        dataIndex: 'server',
        key: 'server',
        customRender: (text, row, index) => {
            return text ? decodeURIComponent(text) : '-';
        }
    },
    {
        title: 'Order ID',
        dataIndex: 'coid',
        key: 'coid',
        scopedSlots: { customRender: 'oid' }
    }
];

export const columnsUse = [
    {
        title: 'Transaction Time',
        dataIndex: 'transaction_time',
        key: 'transaction_time'
    },
    {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        scopedSlots: { customRender: 'location' }
    },
    {
        title: 'Customer ID',
        dataIndex: 'card_number',
        key: 'card_number',
        scopedSlots: { customRender: 'cid' }
    },
    {
        title: 'Phone',
        dataIndex: 'phone_num',
        key: 'phone_num'
    },
    {
        title: 'Channel',
        dataIndex: 'channel',
        key: 'channel'
    },
    {
        title: 'Change',
        dataIndex: 'change',
        key: 'change',
        scopedSlots: { customRender: 'change' }
    },
    {
        title: 'Server',
        dataIndex: 'server',
        key: 'server',
        customRender: (text, row, index) => {
            return text ? decodeURIComponent(text) : '-';
        }
    },
    {
        title: 'Order ID',
        dataIndex: 'coid',
        key: 'coid',
        scopedSlots: { customRender: 'oid' }
    },
    {
        title: 'Note',
        dataIndex: 'note',
        key: 'note',
        customRender: (text, row, index) => {
            return text ? text : '-';
        }
    }
];

export const columnsCanceled = [
    {
        title: 'Transaction Time',
        dataIndex: 'transaction_time',
        key: 'transaction_time'
    },
    {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        scopedSlots: { customRender: 'location' }
    },
    {
        title: 'Customer ID',
        dataIndex: 'card_number',
        key: 'card_number',
        scopedSlots: { customRender: 'cid' }
    },
    {
        title: 'Phone',
        dataIndex: 'phone_num',
        key: 'phone_num'
    },
    {
        title: 'Change',
        dataIndex: 'change',
        key: 'change',
        scopedSlots: { customRender: 'change' }
    },
    {
        title: 'Order ID',
        dataIndex: 'coid',
        key: 'coid',
        scopedSlots: { customRender: 'oid' }
    }
];

export const columnsRefunded = [
    {
        title: 'Credit order time',
        dataIndex: 'create_time',
        key: 'create_time'
    },
    {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        scopedSlots: { customRender: 'location' }
    },
    {
        title: 'Customer ID',
        dataIndex: 'card_number',
        key: 'card_number',
        scopedSlots: { customRender: 'cid' }
    },
    {
        title: 'Phone',
        dataIndex: 'phone_num',
        key: 'phone_num'
    },
    {
        title: 'Change',
        dataIndex: 'change',
        key: 'change',
        scopedSlots: { customRender: 'change' }
    },
    {
        title: 'Server',
        dataIndex: 'server',
        key: 'server',
        customRender: (text, row, index) => {
            return text ? decodeURIComponent(text) : '-';
        }
    },
    {
        title: 'Order ID',
        dataIndex: 'coid',
        key: 'coid',
        scopedSlots: { customRender: 'oid' }
    }
];

export const columnsCashout = [
    {
        title: 'Transaction Time',
        dataIndex: 'transaction_time',
        key: 'transaction_time'
    },
    {
        title: 'Location',
        dataIndex: 'location',
        key: 'location',
        scopedSlots: { customRender: 'location' }
    },
    {
        title: 'Customer ID',
        dataIndex: 'card_number',
        key: 'card_number',
        scopedSlots: { customRender: 'cid' }
    },
    {
        title: 'Phone',
        dataIndex: 'phone_num',
        key: 'phone_num'
    },
    {
        title: 'Change',
        dataIndex: 'change',
        key: 'change',
        scopedSlots: { customRender: 'change' }
    }
];
