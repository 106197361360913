<template>
    <div class="nav" id="menuBar" style="margin-top: 40px; width: 220px">
        <a-menu v-model="current" :open-keys.sync="openKey" style="width: 220px" mode="inline">
            <template v-for="menu in menuFilter(menuList)">
                <a-menu-item v-if="!menu.children || menu.children.length == 0" :key="menu.path" @click="onClickMenu(menu)">
                    <!-- <a-icon :type="menu.meta.icon" /> -->
                    <span>{{ menu.name }}</span>
                </a-menu-item>
                <a-sub-menu v-else :key="menu.path">
                    <span slot="title">
                        <!-- <a-icon :type="menu.meta.icon" /> -->
                        <span>{{ menu.name }}</span>
                    </span>
                    <a-menu-item v-for="subMenu in menuFilter(menu.children)" :key="subMenu.path" @click="onClickMenu(subMenu, menu)" :disabled="!subMenu.path"> {{ subMenu.name }} </a-menu-item>
                </a-sub-menu>
            </template>
        </a-menu>
    </div>
</template>
<style></style>
<script>
import { mapState } from 'vuex';

export default {
    name: 'LeftNav',
    data() {
        return {
            sid: this.$store.state.app.sid,
            current: ['/account'],
            openKey: [],
            menuList: [
                // {
                //     name: '菜单名称',
                //     path: '菜单路由',
                //     meta: {
                //         icon: '菜单图标',
                //         invisible: 'boolean, 是否可见, 默认 false'
                //     },
                //     children: [子菜单配置]
                // },
                {
                    name: 'Account Settings',
                    path: '/account'
                },
                {
                    name: 'Locations',
                    path: '/locations'
                },
                {
                    name: 'Member List',
                    path: '/member'
                },
                {
                    name: 'Loyalty Program',
                    path: '/dashboard1',
                    children: [
                        {
                            name: 'Credits',
                            path: '/creditreports'
                        },
                        {
                            name: 'Points',
                            path: ''
                        }
                    ]
                }
            ]
        };
    },
    filters: {},
    mounted() {
        let active = window.localStorage.getItem('nav_active');
        let open = window.localStorage.getItem('nav_open');
        if (open) {
            this.openKey = [open];
        }
        if (active) {
            this.current = [active];
        }
    },
    watch: {},
    methods: {
        onClickMenu(subMenu, menu) {
            if (!subMenu.path) {
                return;
            }
            window.localStorage.setItem('nav_active', subMenu.path);
            window.localStorage.setItem('nav_open', menu ? menu.path : '');
            this.$router.push({ path: subMenu.path });
        }
    },
    computed: {
        menuFilter() {
            return (list) => {
                return list.filter((item) => {
                    return !item.meta || item.meta.invisible != true;
                });
            };
        }
    }
};
</script>
