import axiosApi from '@/kits/axiosApi';

// initial state
const state = {
    chain_id: window.localStorage.getItem('chain_id') || '',
    role: window.localStorage.getItem('role') || '',
    token: window.localStorage.getItem('token') || '',
    user_name: window.localStorage.getItem('user_name') || '',
    timezone: window.localStorage.getItem('timezone') || '',
    show_loading: false,
    store_dict: {}
};

// getters
const getters = {
    get_chain_id() {
        return state.chain_id;
    },
    get_user_name() {
        return state.user_name;
    },
    get_role() {
        return state.role;
    },
    get_loading(state) {
        return state.show_loading;
    },
    get_timezone(state) {
        return state.timezone || 'America/New_York';
    },
    get_store(state) {
        return state.store_dict || {};
    }
};

// actions
const actions = {
    // queryTimezone({ commit, rootState }, params) {
    //     let aj_host = rootState.aj_host;
    //     return new Promise((resolve, reject) => {
    //         axiosApi
    //             .get(aj_host + '/weapp/admin/store/get', { params })
    //             .then((res) => {
    //                 let timezone = res.data.data.data[0].timezone;
    //                 commit('APP_TIMEZONE', timezone);
    //                 resolve(timezone);
    //             })
    //             .catch((err) => {
    //                 reject(err);
    //             });
    //     });
    // },
    queryStore({ commit, rootState }) {
        let aj_host = rootState.aj_host;
        let params = {
            chain_id: rootState.app.chain_id,
            size: 0
        };
        return new Promise((resolve, reject) => {
            if (JSON.stringify(rootState.app.store_dict) != '{}') {
                resolve(rootState.app.store_dict);
                return;
            }
            axiosApi
                .get(aj_host + '/weapp/admin/store/get', { params })
                .then((res) => {
                    let storeDict = {};
                    for (let i = 0; i < res.data.data.data.list.length; i++) {
                        const item = res.data.data.data.list[i];
                        storeDict[item.sid] = item;
                    }
                    commit('APP_STORE', storeDict);
                    resolve(storeDict);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
};

// mutations
const mutations = {
    APP_SET_LOADING(state, _data) {
        state.show_loading = _data.show_loading;
    },
    APP_TIMEZONE(state, _data) {
        state.timezone = _data;
    },
    APP_STORE(state, _data) {
        state.store_dict = _data;
    },
    APP_SET_GLOBAL_DATA(state, _data) {
        state.chain_id = _data.chain_id;
        state.role = _data.role;
        state.token = _data.token;
        state.user_name = _data.user_name;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
